import React from 'react';
import { TranslationFn, useTranslation } from '@getpopsure/i18n-react';
import {
  calendly,
  insurance,
  app,
  website,
  faq,
} from '@getpopsure/private-constants';
import { OutboundLink } from 'react-ga';

import documentIcon from './img/document.svg';
import branchShield from './img/branch-shield.svg';
import questionShield from './img/question-shield.svg';
import warningShield from './img/warning-shield.svg';
import blockerIcon from './img/blocker.svg';
import styles from './style.module.scss';
import StudentToEmployeeButton from './student-to-employee-button';
import NextQuestionButton from './next-question-button';
import Markdown from 'components/markdown';
import { QuestionId } from 'reducers/questionnaire';
import LinkToQuestionButton from './link-to-question-button';

export type BlockerUrlID =
  | 'student-above-age'
  | 'university-outside-germany'
  | 'student-signed-up-too-late'
  | 'student-too-many-semesters-studied'
  | 'student-working-twenty-hours'
  | 'self-employed-non-eea'
  | 'mini-job-non-eea'
  | 'other-non-eea'
  | 'other-previous-travel'
  | 'intern'
  | 'phd-doctorate'
  | 'other-previous-private'
  | 'employer-has-already-signed-up'
  | 'employer-might-have-already-signed-up'
  | 'employee-switch-provider'
  | 'switch-provider'
  | 'ineligible'
  | 'ineligible-over-private-threshold'
  | 'ineligible-under-private-threshold'
  | 'ineligible-self-employed'
  | 'ineligible-expat-health'
  | 'ineligible-student'
  | 'ineligible-private'
  | 'ineligible-two-months-after-arrival'
  | 'we-can-help-student'
  | 'mini-job-insured-eu';

type Button =
  | 'expat-health-insurance'
  | 'student-over-30'
  | 'private-health-insurance'
  | 'book-appointment'
  | 'employment-status'
  | 'contact-us'
  | 'continue'
  | 'primary-continue'
  | 'expat-health'
  | 'eu-students-germany'
  | 'select-occupation'
  | 'intern-faq'
  | 'phd-faq'
  | 'recommendation-tool';

export const associatedButton = (
  button: Button,
  t: TranslationFn,
  questionId?: QuestionId
) => {
  switch (button) {
    case 'student-over-30':
      return (
        <OutboundLink
          key="student-over-30-link"
          eventLabel="cant_get_covered_expat_health_insurance"
          className={`p-btn p-btn--primary ${styles.button}`}
          to={faq.publicHealthStudentsOver30}
        >
          {t('page.blocker.studentOver30.action', 'Learn about your options')}
        </OutboundLink>
      );
    case 'expat-health-insurance':
      return (
        <OutboundLink
          key="expat-health-insurance-link"
          eventLabel="cant_get_covered_expat_health_insurance"
          className={`p-btn p-btn--primary ${styles.button}`}
          to={insurance.expathHealth.landingPage}
        >
          {t('page.blocker.expathhealth.action', 'Learn about Expat Health')}
        </OutboundLink>
      );
    case 'recommendation-tool':
      return (
        <OutboundLink
          key="expat-health-insurance-link"
          eventLabel="cant_get_covered_expat_health_insurance"
          className={`p-btn p-btn--primary ${styles.button}`}
          to={app.recommendationTool}
        >
          {t(
            'page.blocker.recommendationTool.action',
            'Learn about your options'
          )}
        </OutboundLink>
      );
    case 'private-health-insurance':
      return (
        <OutboundLink
          key="private-health-insurance-link"
          eventLabel="cant_get_covered_private_health_insurance"
          className={`p-btn p-btn--primary ${styles.button}`}
          to={insurance.privateHealth.landingPage}
        >
          {t(
            'page.blocker.privateHealth.action',
            'Learn about private insurance'
          )}
        </OutboundLink>
      );
    case 'book-appointment':
      return (
        <OutboundLink
          key="book-appointment-link"
          eventLabel="cant_get_covered_consultation"
          className={`p-btn p-btn--primary ${styles.button}`}
          to={calendly.genericHelp}
        >
          {t('page.blocker.bookappointment.action', 'Book consultation')}
        </OutboundLink>
      );
    case 'employment-status':
      return <StudentToEmployeeButton />;
    case 'contact-us':
      return (
        <a
          className={`mt16 p-btn--secondary bg-white  ${styles.button}`}
          href={website.support}
        >
          {t('page.blocker.contactus.action', 'Contact us')}
        </a>
      );
    case 'eu-students-germany':
      return (
        <a
          className={`mt16 p-btn--primary ${styles.button}`}
          href={faq.euStudentsComingToGermany}
        >
          {t('page.blocker.eustudentstogermany.action', 'Learn more')}
        </a>
      );
    case 'continue':
      return (
        questionId && (
          <NextQuestionButton
            variant="textColor"
            className={`bg-white ${styles.button}`}
            questionId={questionId}
          >
            {t('page.blocker.continue.action', 'Continue sign-up')}
          </NextQuestionButton>
        )
      );
    case 'primary-continue':
      return (
        questionId && (
          <NextQuestionButton
            className={styles.button}
            variant="filledColor"
            questionId={questionId}
          >
            {t('page.blocker.primarycontinue.action', 'Yes, continue')}
          </NextQuestionButton>
        )
      );
    case 'select-occupation':
      return (
        <LinkToQuestionButton questionId={'occupation'}>
          {t('page.blocker.selectOccupation.action', 'Select an occupation')}
        </LinkToQuestionButton>
      );
    case 'intern-faq':
      return (
        <a
          data-cy="link-to-question"
          className={`p-btn p-btn--primary ${styles.button}`}
          href={faq.internGuide}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('page.blocker.internFAQ.action', 'Read our FAQ')}
        </a>
      );
    case 'phd-faq':
      return (
        <a
          data-cy="link-to-question"
          className={`p-btn p-btn--primary ${styles.button}`}
          href={faq.phdGuide}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('page.blocker.phdFAQ.action', 'Learn about my options')}
        </a>
      );
  }
};
const Blocker = ({
  id,
  questionId,
}: {
  id?: BlockerUrlID;
  questionId?: QuestionId;
}) => {
  const { t } = useTranslation();
  const mapping: {
    [k in BlockerUrlID]?: {
      title?: React.ReactNode;
      text: string;
      icon?: string;
      description?: string;
      buttons: Button[];
    };
  } = {
    'other-non-eea': {
      text: t(
        'page.blocker.noneea.title',
        'Since you moved to Germany from outside of the European Union you can’t sign up for Public Health Insurance yet.'
      ),
      description: t(
        'page.blocker.noneea.description',
        'Instead we recommend signing up for Expat Health Insurance. If you find a job in Germany you can always switch to Public Health Insurance later on'
      ),
      buttons: ['expat-health-insurance'],
    },
    intern: {
      icon: branchShield,
      text: t(
        'page.blocker.intern.title',
        'You might have a few options available'
      ),
      description: t(
        'page.blocker.intern.description',
        'If you are going to do internship in Germany, you may have various insurance options at your disposal, depending on your exact situation. Please find more information in our FAQ.'
      ),
      buttons: ['intern-faq', 'select-occupation'],
    },
    'phd-doctorate': {
      icon: branchShield,
      text: t(
        'page.blocker.phd.title',
        'You might have a few options available'
      ),
      description: t(
        'page.blocker.phd.description',
        'If you are starting a PhD in Germany, you may have various insurance options at your disposal depending on your exact situation. '
      ),
      buttons: ['phd-faq', 'select-occupation'],
    },
    'student-signed-up-too-late': {
      text: t(
        'page.blocker.toolate.title',
        'Since you started studying more than 3 months in the past, you missed the deadline to sign up for Public Health Insurance.'
      ),
      description: t(
        'page.blocker.toolate.description',
        'Instead you should sign up for Expat Health Insurance. After you finish your studies and find a job, you can easily switch to Public Health Insurance.'
      ),
      buttons: ['expat-health-insurance'],
    },
    'student-above-age': {
      text: t(
        'page.blocker.studentabove.title',
        'Since you’ll be over 30 years old at the start of your studies you can’t sign up for Public Health Insurance as a student.'
      ),
      description: t(
        'page.blocker.studentabove.description',
        'But no worries, you have various insurance options at your disposal depending on your exact situation'
      ),
      buttons: ['student-over-30'],
    },
    'self-employed-non-eea': {
      text: t(
        'page.blocker.selfemployed.title',
        'Since you’re self employed and you moved to Germany from outside of the European Union, you can’t sign up for Public Health Insurance.'
      ),
      description: t(
        'page.blocker.selfemployed.description',
        'Instead we recommend that you sign up for Expat Health Insurance. If you decide to become employed in Germany you can always switch to Public Health Insurance.'
      ),
      buttons: ['expat-health-insurance'],
    },
    'mini-job-non-eea': {
      text: t(
        'page.blocker.minijob.title',
        'Since you’re working a mini-job and you moved to Germany from outside of the European Union, you can’t sign up for Public Health Insurance.'
      ),
      description: t(
        'page.blocker.minijob.description',
        'We recommend getting Expat Health Insurance until you get a full or part-time job. Then you can sign up for Public Health Insurance.'
      ),
      buttons: ['expat-health-insurance'],
    },
    'student-working-twenty-hours': {
      text: t(
        'page.blocker.student.text',
        'As you are working for more than 20 hours per week, we cannot sign you up as a student. **Please continue your signup as an employee instead.**'
      ),
      buttons: ['employment-status'],
    },
    'university-outside-germany': {
      text: t(
        'page.blocker.universityoutgermany.title',
        'We’re sorry, we can’t insure students from universities outside of Germany.'
      ),
      description: t(
        'page.blocker.universityoutgermany.description',
        'Instead you should sign up for Expat Health Insurance. After you finish your studies and find a job, you can easily switch to Public Health Insurance'
      ),
      buttons: ['expat-health-insurance'],
    },
    'employer-has-already-signed-up': {
      title: t(
        'page.blocker.employerhasalreadysignedup.title',
        'Your employer should have signed you up already'
      ),
      icon: warningShield,
      text: t(
        'page.blocker.employerhasalreadysignedup.text',
        'Your employer has 6 weeks after your employment start date to sign you up for health insurance. You are likely already insured and should contact your employer for more details about your health insurance.'
      ),
      description: t(
        'page.blocker.employerhasalreadysignedup.description',
        'If you’re not yet insured, reach out to us.'
      ),
      buttons: ['contact-us'],
    },
    'employer-might-have-already-signed-up': {
      title: t(
        'page.blocker.employermighthavealreadysignedup.title',
        'Your employer might have signed you up already'
      ),
      icon: questionShield,
      text: t(
        'page.blocker.employermighthavealreadysignedup.text',
        'You have the freedom to choose your health insurance provider up until 14 days after starting your new employment. If you haven’t discussed health insurance with your employer and it’s been more than 14 days, they might have signed you up for coverage already.'
      ),
      description: t(
        'page.blocker.employermighthavealreadysignedup.description',
        'If you spoke with your employer and they didn’t insure you yet, please proceed with your application here.'
      ),
      buttons: ['continue'],
    },
    'employee-switch-provider': {
      title: t(
        'page.blocker.employeeswitchprovider.title',
        'Do you want to switch providers?'
      ),
      icon: questionShield,
      text: t(
        'page.blocker.employeeswitchprovider.text',
        'You can switch providers and waive the binding period of one year within the first month after a price increase.\n\nSwitching insurance providers without any limitations is only possible when you sign up within 14 days of a new employment.'
      ),
      description: t(
        'page.blocker.employeeswitchprovider.description',
        'In all other cases, you must stay with your current provider for **at least one whole year** and likely have a [cancellation period]({{cancellationPeriodLink}}) of **2 full calendar months**. Your new policy will start following that cancellation period.',
        { cancellationPeriodLink: faq.cancelPublicHealthInsurance }
      ),
      buttons: ['primary-continue', 'contact-us'],
    },
    'switch-provider': {
      title: t(
        'page.blocker.switchprovider.title',
        'Do you want to switch providers?'
      ),
      icon: questionShield,
      text: t(
        'page.blocker.switchprovider.text',
        'You can switch your insurance provider if you have been with your current provider for **at least one whole year**.'
      ),
      description: t(
        'page.blocker.switchprovider.description',
        'Keep in mind that in most cases there is a [cancellation period]({{cancellationPeriodLink}}) of at least **2 full calendar months** with public providers. If this is the case, your new policy will start following that cancellation period.',
        { cancellationPeriodLink: faq.cancelPublicHealthInsurance }
      ),
      buttons: ['primary-continue', 'contact-us'],
    },
    ineligible: {
      title: t(
        'page.blocker.ineligible.title',
        'You’re currently not eligible'
      ),
      icon: warningShield,
      text: t(
        'page.blocker.ineligible.text',
        'Eligibility for public health insurance depends on your occupation, income, and other factors like your insurance history. You’re not [meeting the requirements]({{publicHealthRequirements}}) for being insured on public health insurance, but might be eligible for expat insurance instead.',
        { publicHealthRequirements: faq.eligibilityPublicHealthInsurance }
      ),
      buttons: ['expat-health-insurance', 'contact-us'],
    },
    'ineligible-over-private-threshold': {
      title: t(
        'page.blocker.ineligibleOverPrivateThreshold.title',
        'You’re currently not eligible'
      ),
      icon: warningShield,
      text: t(
        'page.blocker.ineligibleOverPrivateThreshold.text',
        'Eligibility for public health insurance depends on your occupation, income, and other factors like your insurance history. You’re not [meeting the requirements]({{publicHealthRequirements}}) for being insured on public health insurance, but might be eligible for private insurance instead.',
        { publicHealthRequirements: faq.eligibilityPublicHealthInsurance }
      ),
      buttons: ['private-health-insurance', 'contact-us'],
    },
    'ineligible-under-private-threshold': {
      title: t(
        'page.blocker.ineligibleUnderPrivateThreshold.title',
        'You’re currently not eligible'
      ),
      icon: warningShield,
      text: t(
        'page.blocker.ineligibleUnderPrivateThreshold.text',
        'Eligibility for public health insurance depends on your occupation, income, and other factors like your insurance history. You’re not [meeting the requirements]({{publicHealthRequirements}}) for being insured on public health insurance',
        { publicHealthRequirements: faq.eligibilityPublicHealthInsurance }
      ),
      buttons: ['contact-us'],
    },
    'ineligible-expat-health': {
      title: t(
        'page.blocker.ineligibleExpatHealth.title',
        'You’re currently not eligible'
      ),
      icon: warningShield,
      text: t(
        'page.blocker.ineligibleExpatHealth.text',
        'Eligibility for public health insurance depends on your occupation, income, and other factors like your insurance history. You’re not [meeting the requirements]({{publicHealthRequirements}}) for being insured on public health insurance, but might be eligible for expat health insurance instead.',
        { publicHealthRequirements: faq.eligibilityPublicHealthInsurance }
      ),
      buttons: ['expat-health-insurance', 'contact-us'],
    },
    'ineligible-self-employed': {
      icon: warningShield,
      title: t(
        'page.blocker.ineligibleSelfEmployed.title',
        'You’re currently not eligible'
      ),
      text: t(
        'page.blocker.ineligibleSelfEmployed.description',
        'Eligibility for public health insurance depends on your occupation, income, and other factors like your insurance history. You’re not [meeting the requirements]({{publicHealthRequirements}}) for being insured on public health insurance.',
        { publicHealthRequirements: faq.eligibilityPublicHealthInsurance }
      ),
      buttons: ['recommendation-tool', 'contact-us'],
    },
    'ineligible-student': {
      title: t(
        'page.blocker.ineligiblestudent.title',
        'You are not eligible for student public health insurance'
      ),
      icon: warningShield,
      text: t(
        'page.blocker.ineligiblestudent.text',
        'If you are currently on private or incoming (expat) insurance and want to switch in the middle of studies, you are unfortunately not eligible for public health insurance as a student because you got exempted at the beginning of studies. A mini-job or working student contract don’t make you eligible, and you have to stay on your insurance until you get a regular employment contract with more than 20 hours per week.'
      ),
      buttons: ['expat-health-insurance', 'contact-us'],
    },
    'ineligible-private': {
      title: t(
        'page.blocker.ineligiblePrivate.title',
        'You’re currently not eligible'
      ),
      icon: warningShield,
      text: t(
        'page.blocker.ineligiblePrivate.text',
        'Since you were previously on private health insurance in Germany, it’s not possible for you to switch to the public system.'
      ),
      buttons: ['private-health-insurance', 'contact-us'],
    },
    'ineligible-two-months-after-arrival': {
      title: t(
        'page.blocker.ineligibleTwoMonthsAfterArrival.title',
        'You’re currently not eligible'
      ),
      icon: warningShield,
      text: t(
        'page.blocker.ineligibleTwoMonthsAfterArrival.text',
        'If you’re returning to Germany to work again, you have to start your employment within 2 months of arrival in order to be eligible for public health insurance.'
      ),
      buttons: ['private-health-insurance', 'contact-us'],
    },
    'we-can-help-student': {
      title: t(
        'page.blocker.wecanhelpstudent.title',
        'We can help with the insurance confirmation'
      ),
      icon: documentIcon,
      text: t(
        'page.blocker.wecanhelpstudent.text',
        'Since you will not sign up for health insurance in Germany, your university will require the confirmation of your valid home insurance (M10 report), and it can only come from a German public health insurance provider. We can help you with that.'
      ),
      description: t(
        'page.blocker.wecanhelpstudent.description',
        'Have in mind to always confirm with your home insurance provider the conditions under which they will cover your stay in Germany during studies.'
      ),
      buttons: ['eu-students-germany'],
    },
    'mini-job-insured-eu': {
      title: t(
        'page.blocker.minijobEu.title',
        'Expat insurance could be a better option for you'
      ),
      icon: warningShield,
      text: t(
        'page.blocker.minijobEu.description',
        'Since you are earning under the threshold of €{{miniJobThreshold}} per month, you cannot be insured through your employer and will need to pay the full contribution yourself — around €220 per month. This is definitely a good option if you have ongoing health issues and need constant treatment, since public insurance offers a broad scope of coverage.\n\nHowever, if that’s not the case and you want to sign up for a more affordable insurance, expat insurance might be a better choice. It starts at €72 per month, and you can still easily switch to public insurance through your employer if you start earning over €{{miniJobThreshold}} per month.\n\nOne thing to keep in mind: if you plan on becoming self-employed directly after the minijob, you will only be eligible for public if your home insurance is still valid. In that case, choosing public insurance now might be your best option, even if the contribution is higher.',
        {
          miniJobThreshold: insurance.healthInsurance.miniJobThreshold,
        }
      ),
      buttons: ['expat-health-insurance', 'continue'],
    },
  };

  const content = id ? mapping[id] : undefined;

  if (content && id) {
    return (
      <div className="p-body wmx8 public-signup-center-element jc-center ml-auto mr-auto">
        <div className={styles.container}>
          <div className={styles.emoji}>
            <img src={content?.icon || blockerIcon} alt="" />
          </div>
          <div className={`p-h1 ${styles.title}`}>
            {content?.title || 'We’re sorry...'}
          </div>
          <div className={`p-p ${styles.subtitle}`}>
            <Markdown>{content.text}</Markdown>
          </div>
          {content.description && (
            <div className={`p-p ${styles.reasons}`}>
              <Markdown>{content.description}</Markdown>
            </div>
          )}
          <div className={styles['button-container']}>
            {content.buttons.map((button) =>
              associatedButton(button, t, questionId)
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-body public-signup-center-element">
      <div className={`mr-auto ml-auto ${styles.container}`}>
        <div className={styles.emoji}>
          <span role="img" aria-label="">
            😕
          </span>
        </div>
        <div className={`p-h1 ${styles.title}`}>
          {t('page.blocker.generic.title', 'We’re sorry')}
        </div>
        <p className={`p-p ${styles.subtitle}`}>
          {t(
            'page.blocker.generic.subtitle',
            'From the information that we collected from you, it seems we are unable to sign you up for public health insurance.'
          )}
        </p>
        <p className={`p-p ${styles.reasons}`}>
          {t(
            'page.blocker.generic.description',
            "We're happy to help you determine what other options might be available to you."
          )}
        </p>
        <OutboundLink
          eventLabel="cant_get_covered_recommendation_tool_link"
          className={`p-btn p-btn--primary wmn3 ${styles.button}`}
          to={app.recommendationTool}
        >
          {t('page.blocker.generic.consultation.action', 'Find out')}
        </OutboundLink>
      </div>
    </div>
  );
};

export default Blocker;
