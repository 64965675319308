import { connect } from 'react-redux';

import { answeredQuestion, skippedQuestion } from 'actions/questionnaire';
import Questionnaire from 'components/questionnaire';
import { ConfirmSignedUpByEmployerCta } from 'components/questionnaire/questions/confirmSignedUpByEmployer';
import { QuestionId, QuestionnaireState } from 'reducers/questionnaire';
import { AppState } from 'reducers';
import { getReachableQuestions } from 'reducers/questionnaire/order';

import {
  getRequestInProcess,
  getRequestError,
  formatErrorMessageFromError,
} from 'reducers/request';
import Session from '@getpopsure/session';
import { provider } from 'util/environment';

interface StateProps {
  initialData?: Partial<QuestionnaireState>;
  reachableQuestions: QuestionId[];
  isSkippable: boolean;
  isLoading: boolean;
  error?: string;
  needsAuthentication: boolean;
}

interface DispatchProps {
  onAnsweredQuestion: (answer: any) => void;
  onSkip: () => void;
}

type OwnProps = {
  questionId: QuestionId;
  customCta?: () => JSX.Element;
  hideCta?: boolean;
};

export type ViewProps = StateProps & DispatchProps & OwnProps;

const getCustomCta = (questionId: QuestionId) => {
  if (questionId === 'confirmNotSignedUpByEmployer') {
    return ConfirmSignedUpByEmployerCta;
  }
};

const shouldHideCta = (questionId: QuestionId) =>
  [
    'switchProviders',
    'switchEmployedProviders',
    'employerHasAlreadySignedUp',
    'employerMightHaveAlreadySignedUp',
    'miniJobInsuredEU',
  ].includes(questionId);

const canSkipQuestion = (questionId: QuestionId) => {
  return [
    ...(provider === 'bkk' ? [] : ['address']),
    'germanPensionNumber',
    'previousInsurer',
    'lastProviderInGermany',
  ].includes(questionId);
};

const mapStateToProps = (state: AppState, { questionId }: OwnProps) => {
  const isSkippable = canSkipQuestion(questionId);

  const isLoading = getRequestInProcess(state.request, 'SUBMIT_QUESTIONNAIRE');
  let error = getRequestError(state.request, 'SUBMIT_QUESTIONNAIRE');
  const needsAuthentication =
    (!Session.isAuthenticated && state.session && questionId !== 'email') ||
    error?.response?.status === 403;

  if (needsAuthentication) {
    error = undefined;
  }

  return {
    initialData: state.questionnaire,
    reachableQuestions: getReachableQuestions(state.questionnaire),
    isLoading,
    isSkippable,
    needsAuthentication,
    error: error && formatErrorMessageFromError(error),
    customCta: getCustomCta(questionId),
    hideCta: shouldHideCta(questionId),
  };
};

const mapDispatchToProps = (dispatch: any, { questionId }: OwnProps) => ({
  onAnsweredQuestion: (answer: any) => {
    dispatch(answeredQuestion(questionId, answer));
  },
  onSkip: () => {
    dispatch(skippedQuestion(questionId));
  },
});

export default connect<StateProps, DispatchProps, OwnProps, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(Questionnaire);
