import { QuestionId, QuestionnaireState } from '..';
import { routes } from 'routes';

import { isPartOfEuropeanHealthNetwork } from 'util/isPartOfEHN';
import { getSalaryType } from 'util/getSalaryType';
import { isEmployed } from 'util/isEmployed';
import { getAgeAtStartDate } from '../../../util/getAgeAtStartDate';

export type QuestionnaireBlocker =
  | 'ABOVE_30_STUDENT'
  | 'STUDENT_TOO_MANY_SEMESTERS'
  | 'STUDENT_WORKS_MORE_THAN_TWENTY_HOURS'
  | 'UNIVERSITY_OUTSIDE_GERMANY'
  | 'TAX_QUESTION_PREVIOUS_INSURANCE_TRAVEL'
  | 'LEARN_MORE_INTERN'
  | 'LEARN_MORE_PHD'
  | 'TAX_QUESTION_PREVIOUS_INSURANCE_PRIVATE'
  | 'FAMILY_REUNION_VISA'
  | 'IS_SPOUSE'
  | 'EMPLOYER_HAS_ALREADY_SIGNED_UP'
  | 'EMPLOYER_MIGHT_HAVE_ALREADY_SIGNED_UP'
  | 'INELIGIBLE_EXPAT_HEALTH'
  | 'INELIGIBLE_OVER_PRIVATE_THRESHOLD'
  | 'INELIGIBLE_UNDER_PRIVATE_THRESHOLD'
  | 'INELIGIBLE_SELF_EMPLOYED'
  | 'INELIGIBLE_STUDENT'
  | 'INELIGIBLE_PRIVATE'
  | 'INELIGIBLE_TWO_MONTHS_AFTER_ARRIVAL'
  | 'STUDENT_EHIC_CARD';

export const pathForBlocker = (blocker: QuestionnaireBlocker): string => {
  switch (blocker) {
    case 'ABOVE_30_STUDENT':
      return routes.blocker.studentAboveAge.path;
    case 'STUDENT_TOO_MANY_SEMESTERS':
      return routes.blocker.generic.path;
    case 'UNIVERSITY_OUTSIDE_GERMANY':
      return routes.blocker.universityOutsideOfGermany.path;
    case 'STUDENT_WORKS_MORE_THAN_TWENTY_HOURS':
      return routes.blocker.studentWorkingTwentyHours.path;
    case 'TAX_QUESTION_PREVIOUS_INSURANCE_TRAVEL':
      return routes.blocker.otherPreviousTravel.path;
    case 'LEARN_MORE_INTERN':
      return routes.blocker.intern.path;
    case 'LEARN_MORE_PHD':
      return routes.blocker.phdDoctorate.path;
    case 'TAX_QUESTION_PREVIOUS_INSURANCE_PRIVATE':
      return routes.blocker.otherPreviouslyPrivate.path;
    case 'FAMILY_REUNION_VISA':
      return routes.blocker.familyReunionVisa.path;
    case 'IS_SPOUSE':
      return routes.blocker.spouse.path;
    case 'EMPLOYER_HAS_ALREADY_SIGNED_UP':
      return routes.blocker.employerHasAlreadySignedUp.path;
    case 'EMPLOYER_MIGHT_HAVE_ALREADY_SIGNED_UP':
      return routes.blocker.employerMightHaveAlreadySignedUp.path;
    case 'INELIGIBLE_EXPAT_HEALTH':
      return routes.blocker.ineligibleExpatHealth.path;
    case 'INELIGIBLE_OVER_PRIVATE_THRESHOLD':
      return routes.blocker.ineligibleOverPrivateThreshold.path;
    case 'INELIGIBLE_UNDER_PRIVATE_THRESHOLD':
      return routes.blocker.ineligibleUnderPrivateThreshold.path;
    case 'INELIGIBLE_SELF_EMPLOYED':
      return routes.blocker.ineligibleSelfEmployed.path;
    case 'INELIGIBLE_STUDENT':
      return routes.blocker.ineligibleStudent.path;
    case 'INELIGIBLE_PRIVATE':
      return routes.blocker.ineligiblePrivate.path;
    case 'INELIGIBLE_TWO_MONTHS_AFTER_ARRIVAL':
      return routes.blocker.ineligibleTwoMonthsAfterArrival.path;
    case 'STUDENT_EHIC_CARD':
      return routes.blocker.weCanHelpStudent.path;
  }
};

export const getBlocker = (
  state: QuestionnaireState,
  questionId: QuestionId
): QuestionnaireBlocker | undefined => {
  const { dateOfBirth } = state;
  const startDate =
    state.occupation === 'UNIVERSITY_STUDENT'
      ? state.universityStartDate
      : state.jobStartDate;

  const ageAtStartDate = getAgeAtStartDate(startDate, dateOfBirth);

  const hasCustomOccupation =
    state.occupation === 'OTHER' && state.otherSituation === 'OTHER';
  const isHighEarner =
    state.annualIncome &&
    getSalaryType(state.annualIncome) === 'EXCEED_PRIVATE_THRESHOLD';

  if (isHighEarner) {
    if (
      (questionId === 'currentlyOnPrivate' && state.currentlyOnPrivate) ||
      (questionId === 'lastInsuranceInGermany' &&
        state.lastInsuranceInGermany === 'PRIVATE')
    ) {
      return 'INELIGIBLE_PRIVATE';
    }

    if (
      questionId === 'employmentWithinTwoMonthsOfArrival' &&
      !state.employmentWithinTwoMonthsOfArrival
    ) {
      return 'INELIGIBLE_TWO_MONTHS_AFTER_ARRIVAL';
    }
  }

  if (
    state.occupation === 'UNIVERSITY_STUDENT' &&
    questionId === 'universityStartDate'
  ) {
    return ageAtStartDate >= 30 ? 'ABOVE_30_STUDENT' : undefined;
  }

  if (state.otherSituation === 'INTERN' && questionId === 'otherSituation') {
    return 'LEARN_MORE_INTERN';
  }

  if (state.otherSituation === 'PHD' && questionId === 'otherSituation') {
    return 'LEARN_MORE_PHD';
  }

  if (
    state.occupation === 'UNIVERSITY_STUDENT' &&
    state.worksMoreThanTwentyHours &&
    questionId === 'worksMoreThanTwentyHours'
  ) {
    return 'STUDENT_WORKS_MORE_THAN_TWENTY_HOURS';
  }

  if (
    state.occupation === 'UNIVERSITY_STUDENT' &&
    !state.universityInGermany &&
    questionId === 'universityInGermany'
  ) {
    return 'UNIVERSITY_OUTSIDE_GERMANY';
  }

  if (
    state.occupation === 'UNIVERSITY_STUDENT' &&
    state?.expatOrPrivateHealthInsuranceIncoming &&
    questionId === 'expatOrPrivateHealthInsuranceIncoming'
  ) {
    return 'INELIGIBLE_STUDENT';
  }

  if (
    state.occupation === 'UNIVERSITY_STUDENT' &&
    state?.ehicCard &&
    questionId === 'ehicCard'
  ) {
    return 'STUDENT_EHIC_CARD';
  }

  const employedOver55 = isEmployed(state.occupation) && ageAtStartDate >= 55;
  if (
    questionId === 'pastFiveYearsInGermany' &&
    state.pastFiveYearsInGermany &&
    employedOver55
  ) {
    if (isHighEarner) {
      return 'INELIGIBLE_OVER_PRIVATE_THRESHOLD';
    }

    if (!isHighEarner) {
      return 'INELIGIBLE_UNDER_PRIVATE_THRESHOLD';
    }
  }

  if (
    questionId === 'pastFiveYearsInGermany' &&
    state.pastFiveYearsInGermany &&
    !hasCustomOccupation
  ) {
    return 'INELIGIBLE_EXPAT_HEALTH';
  }

  if (
    questionId === 'insuranceTwentyFourMonths' &&
    !state.insuranceTwentyFourMonths &&
    !hasCustomOccupation
  ) {
    return 'INELIGIBLE_EXPAT_HEALTH';
  }

  if (
    questionId === 'hasPublicHealthInsurance' &&
    state.occupation === 'SELF_EMPLOYED' &&
    !state.hasPublicHealthInsurance
  ) {
    return 'INELIGIBLE_SELF_EMPLOYED';
  }

  if (
    state.occupation !== 'UNIVERSITY_STUDENT' &&
    !employedOver55 &&
    !hasCustomOccupation
  ) {
    if (
      questionId === 'hasPublicHealthInsurance' &&
      !state.hasPublicHealthInsurance
    ) {
      return 'INELIGIBLE_EXPAT_HEALTH';
    }

    if (
      questionId === 'previousCountryOfInsurance' &&
      !isPartOfEuropeanHealthNetwork(state?.previousCountryOfInsurance)
    ) {
      return 'INELIGIBLE_EXPAT_HEALTH';
    }
  }

  if (
    state.occupation === 'OTHER' &&
    state.otherSituation === 'FAMILY_REUNION' &&
    questionId === 'otherSituation'
  ) {
    return 'FAMILY_REUNION_VISA';
  }

  if (
    state.occupation === 'OTHER' &&
    state.otherSituation === 'SPOUSE' &&
    questionId === 'otherSituation'
  ) {
    return 'IS_SPOUSE';
  }
};
