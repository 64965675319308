import { Gender as GenderType } from '@getpopsure/public-models';
import { blog, faq, insurance } from '@getpopsure/private-constants';

import {
  ArgenturFurArgeitBenefits,
  LastInsuranceInGermanyType,
  Occupation as OccupationType,
  OtherIncome as OtherIncomeType,
  OtherSituation as OtherSituationType,
} from 'models/index';

import TextQuestion from './textQuestion';
import DateQuestion from './dateQuestion';
import SelectorQuestion from './selectorQuestion';
import AddressQuestion from './addressQuestion';
import CountryMultiQuestion from './countryMultiQuestion';
import CountrySingleQuestion from './countrySingleQuestion';
import BooleanQuestion from './booleanQuestion';
import PlaceAndCountryOfBirthQuestion from './placeAndCountryOfBirthQuestion';
import PublicHealthInsuranceQuestion from './publicHealthInsuranceQuestion';
import { WithTitle } from './withTitle';
import { provider } from 'util/environment';
import dayjs from 'dayjs';
import UniversityNameQuestion from './universityNameQuestion';
import { useTranslation } from '@getpopsure/i18n-react';
import CurrencyQuestion from './currencyQuestion';
import { InformationBox } from '@popsure/dirty-swan';
import AnimateHeight from 'react-animate-height';

const Name = () => {
  const { t } = useTranslation();
  const validator = (value: string) => !value;

  return (
    <WithTitle title={t('page.question.name.title', 'What’s your full name?')}>
      <div className="mb8">
        <TextQuestion
          name="name.firstName"
          placeholder={t(
            'page.question.firstName.placeholder',
            'First name(s)'
          )}
          validate={validator}
        />
      </div>
      <TextQuestion
        name="name.lastName"
        placeholder={t('page.question.lastName.placeholder', 'Last name(s)')}
        validate={validator}
      />
    </WithTitle>
  );
};

const DateOfBirth = () => {
  const { t } = useTranslation();

  return (
    <WithTitle
      title={t('page.question.dateofbirth.title', 'What’s your date of birth?')}
    >
      <DateQuestion
        name="dateOfBirth"
        yearBoundaries={{ min: 1900, max: dayjs().year() }}
      />
    </WithTitle>
  );
};

const Gender = () => {
  const isTK = provider === 'tk';
  const { t } = useTranslation();
  const values: Array<{ value: GenderType; text: string }> = [
    {
      value: 'MALE',
      text: t('page.question.gender.male', 'Male'),
    },
    {
      value: 'FEMALE',
      text: t('page.question.gender.female', 'Female'),
    },
    ...(isTK
      ? []
      : [
          {
            value: 'OTHER' as GenderType,
            text: t(
              'page.question.gender.nonconforming',
              'Gender nonconforming'
            ),
          },
        ]),
  ];

  return (
    <WithTitle title={t('page.question.gender.title', 'What’s your gender?')}>
      <SelectorQuestion name="gender" type="radio" values={values} />
    </WithTitle>
  );
};

const HasAddressInGermany = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.hasaddress.title',
        'Do you have a German address?'
      )}
    >
      <BooleanQuestion name="hasAddressInGermany" />
    </WithTitle>
  );
};

const Address = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.address.title',
        'What’s your address in Germany?'
      )}
      subtitle={t(
        'page.question.address.subtitle',
        'You’ll get your health card by mail 2-3 weeks after your coverage is confirmed and you uploaded your photo. Make sure to fill the C/O field if your name is not on the front door and mailbox.'
      )}
    >
      <AddressQuestion name="address" />
    </WithTitle>
  );
};

const Occupation = () => {
  const { t } = useTranslation();
  const values: Array<{ value: OccupationType; text: string }> = [
    {
      value: 'UNIVERSITY_STUDENT',
      text: t('page.question.occupation.student', 'University student'),
    },
    {
      value: 'EMPLOYED',
      text: t('page.question.occupation.employed', 'Employed'),
    },
    {
      value: 'JOB_OFFER',
      text: t('page.question.occupation.jobOffer', 'I have a job offer'),
    },
    {
      value: 'SELF_EMPLOYED',
      text: t('page.question.occupation.selfemployed', 'Self-employed'),
    },
    {
      value: 'OTHER',
      text: t('page.question.occupation.otherOccupation', 'Other'),
    },
  ];

  return (
    <WithTitle
      title={t(
        'page.question.occupation.title',
        'What’s your main occupation in Germany?'
      )}
      subtitle={t(
        'page.question.occupation.subtitle',
        'This can be your upcoming occupation, e.g. if you have a job lined up.'
      )}
    >
      <SelectorQuestion name="occupation" values={values} type="radio" />
    </WithTitle>
  );
};

const UniversityInGermany = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.universityinde.title',
        'Is your university located in Germany?'
      )}
    >
      <BooleanQuestion name="universityInGermany" />
    </WithTitle>
  );
};

const UniversityName = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.universityname.title',
        'Which university will you be studying at?'
      )}
    >
      <UniversityNameQuestion name="university" />
    </WithTitle>
  );
};

const WorksMoreThanTwentyHours = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.workmorethan20hours.title',

        'Are you working more than 20 hours per week as an employee or a freelancer?'
      )}
      hint={{
        title: t(
          'page.question.workmorethan20hours.hint.title',
          'Working student rules'
        ),
        description: t(
          'page.question.workmorethan20hours.hint.description',
          'If you have a working student (Werkstudent) or internship contract, you are allowed to work more than 20h per week during certain periods. If that\'s the case, your can select "no" \n\n [Learn more](https://faq.eu.feather-insurance.com/en/articles/28594-i-want-to-work-while-studying-what-are-the-exact-rules)'
        ),
      }}
    >
      <BooleanQuestion name="worksMoreThanTwentyHours" />
    </WithTitle>
  );
};

const UniversityStartDate = () => {
  const { t } = useTranslation();
  const currentYear = dayjs().year();
  return (
    <WithTitle
      title={t(
        'page.question.unistartdate.title',
        'When does your first semester begin?'
      )}
      subtitle={t(
        'page.question.unistartdate.subtitle',
        'The date is most likely going to be the 1st of April or 1st of October.'
      )}
    >
      <DateQuestion
        name="universityStartDate"
        yearBoundaries={{ min: currentYear - 6, max: currentYear + 2 }}
      />
    </WithTitle>
  );
};

const JobTitle = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t('page.question.jobtitle.title', 'What is your job title')}
    >
      <TextQuestion name="jobTitle" />
    </WithTitle>
  );
};

const FirstTimeEmployedInGermany = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.firstimeemployed.title',
        'Is this your first time being employed in Germany?'
      )}
      subtitle={t(
        'page.question.firstimeemployed.subtitle',
        'Mini-jobs, student jobs and unpaid internships don’t count.'
      )}
    >
      <BooleanQuestion name="firstTimeEmployedInGermany" />
    </WithTitle>
  );
};

const PreviousInsurer = () => {
  const { t } = useTranslation();

  const questionTitle = t(
    'page.question.previousinsurerv2.title',
    'What’s your health insurance provider’s name?'
  );

  return (
    <WithTitle title={questionTitle}>
      <PublicHealthInsuranceQuestion name="previousInsurer" />
    </WithTitle>
  );
};

const LastProviderInGermany = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.lastProviderInGermany.title',
        'Which public health provider were you last with in Germany?'
      )}
    >
      <PublicHealthInsuranceQuestion name="lastProviderInGermany" />
    </WithTitle>
  );
};

const EmployerName = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t('page.question.employername.title', 'Who is your employer?')}
    >
      <TextQuestion name="employerName" />
    </WithTitle>
  );
};

const UnlistedPreviousInsurer = () => {
  const { t } = useTranslation();

  return (
    <WithTitle
      title={t(
        'page.question.unlistedpreviousinsurer.title',
        'Enter the name of the provider'
      )}
    >
      <TextQuestion name="unlistedPreviousInsurer" />
    </WithTitle>
  );
};

const EmployerAddress = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.employeraddress.title',
        'What’s the company address?'
      )}
    >
      <AddressQuestion name="companyAddress" />
    </WithTitle>
  );
};

const BeginningOfContract = () => {
  const { t } = useTranslation();
  const currentYear = dayjs().year();
  return (
    <WithTitle
      title={t(
        'page.question.beginningofcontract.title',
        'What is the start date of your employment contract?'
      )}
    >
      <DateQuestion
        name="jobStartDate"
        yearBoundaries={{ min: currentYear - 50, max: currentYear + 2 }}
      />
    </WithTitle>
  );
};

const AnnualIncome = () => {
  const { t } = useTranslation();

  return (
    <WithTitle
      title={t(
        'page.question.annualIncome.title',
        'What’s your yearly employment income before taxes?'
      )}
    >
      <CurrencyQuestion
        name="annualIncome"
        placeholder={t(
          'page.question.annualIncome.placeholder',
          'Yearly income in Euros'
        )}
        showMessage={{
          min: insurance.healthInsurance.miniJobThreshold * 12,
          infoBoxProps: {
            variant: 'information',
            children: t(
              'page.question.annualIncome.miniJobInfo',
              'This means you have a minijob. Make sure this is correct.'
            ),
          },
        }}
      />
    </WithTitle>
  );
};

const GermanPensionNumber = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.germanpensionnumber.title',
        'What is your German pension number?'
      )}
    >
      <TextQuestion name="germanPensionNumber" />
    </WithTitle>
  );
};

const LastNameAtBirth = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.lastnameatbirth.title',
        'What was your last name at birth?'
      )}
    >
      <TextQuestion name="lastNameAtBirth" />
    </WithTitle>
  );
};

const PlaceAndCountryOfBirth = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.placeandcountryofbirth.title',
        'What is your city and country of birth?'
      )}
    >
      <PlaceAndCountryOfBirthQuestion name="placeOfBirth" />
    </WithTitle>
  );
};

const Nationality = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.nationality.title',
        'Which countries do you have citizenship in?'
      )}
    >
      <CountryMultiQuestion name="nationality" />
    </WithTitle>
  );
};

const ReceivesPension = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.receivespension.title',
        'Are you currently receiving any money from a pension fund (state or non-state pension)?'
      )}
      subtitle={t(
        'page.question.receivespension.subtitle',
        'For most non-retired persons, the answer is no'
      )}
    >
      <BooleanQuestion name="receivesPension" />
    </WithTitle>
  );
};

const DependentsToGetCovered = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.dependentstocover.title',
        'Would you like to cover dependents under your insurance policy?'
      )}
      subtitle={
        <p>
          {t(
            'page.question.dependentstocover.subtitle',
            'E.g., children or a non-working spouse permanently living in Germany.'
          )}{' '}
          <a
            className="p-a c-pointer"
            href={blog.addDependentsToHealthInsurance}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('page.question.dependentstocover.action', 'Find out more')}
          </a>
        </p>
      }
    >
      <BooleanQuestion
        name="hasDependentsToCover"
        renderAdditionalInfo={(selectedValue) => (
          <AnimateHeight duration={500} height={selectedValue ? 'auto' : 0}>
            {selectedValue ? (
              <InformationBox variant="information" className="wmx5 mt24">
                {t(
                  'page.question.dependents.cover.info',
                  'You will receive an email with instructions on how to add dependents after you sign up.'
                )}
              </InformationBox>
            ) : null}
          </AnimateHeight>
        )}
      />
    </WithTitle>
  );
};

const HasDependentChildren = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.hasdependentchildren.title',
        'Do you have any dependent children?'
      )}
      subtitle={t(
        'page.question.hasdependentchildren.subtitle',
        'Claiming dependent children can lower your monthly bill.'
      )}
    >
      <BooleanQuestion name="hasDependentChildren" />
    </WithTitle>
  );
};

const PreviousCountryOfInsurance = () => {
  const { t } = useTranslation();

  return (
    <WithTitle
      title={t(
        'page.question.previouscountryofinsurancev2.title',
        'In which country are you publicly insured?'
      )}
    >
      <CountrySingleQuestion name="previousCountryOfInsurance" />
    </WithTitle>
  );
};

const OtherSituation = () => {
  const { t } = useTranslation();
  const values: Array<{ value: OtherSituationType; text: string }> = [
    {
      value: 'RETIRED',
      text: t('page.question.othersituation.retired', 'Retired'),
    },
    {
      value: 'LANGUAGE_SCHOOL',
      text: t(
        'page.question.othersituation.languageschool',
        'Attending language school'
      ),
    },
    {
      value: 'JOB_HUNTING',
      text: t('page.question.othersituation.jobsearch', 'Searching for a job'),
    },
    {
      value: 'FAMILY_REUNION',
      text: t(
        'page.question.othersituation.familyreunionvisa',
        'Family reunion visa'
      ),
    },
    {
      value: 'APPRENTICE',
      text: t('page.question.othersituation.apprentice', 'Apprentice (Azubi)'),
    },
    {
      value: 'FSJ',
      text: t(
        'page.question.othersituation.fsj',
        'Freiwilliges Soziales Jahr (voluntary year)'
      ),
    },
    {
      value: 'PHD',
      text: t('page.question.othersituation.phd', 'PhD/Doctorate'),
    },
    {
      value: 'INTERN',
      text: t('page.question.othersituation.intern', 'Intern'),
    },
    {
      value: 'OTHER',
      text: t('page.question.othersituation.otheroption', 'Other'),
    },
  ];

  return (
    <WithTitle
      title={t('page.question.othersituation.title', 'What’s your situation?')}
    >
      <SelectorQuestion name="otherSituation" type="radio" values={values} />
    </WithTitle>
  );
};

const OtherSituationDescription = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.othersituationdescription.title',
        'Describe your situation in detail'
      )}
      subtitle={t(
        'page.question.othersituationdescription.subtitle',
        'Let us know about your **occupation**, **date of arrival** in Germany, if you have a **residence permit** (if yes, for how long), as well as any other relevant details. This will help us determine your eligibility.'
      )}
    >
      <TextQuestion
        component="textarea"
        name="otherSituationDescription"
        placeholder={t(
          'page.question.othersituationdescription.placeholder',
          'e.g., I’m a civil servant on a National D Visa, in Germany since 2022.'
        )}
      />
    </WithTitle>
  );
};

const HowLongInLanguageSchool = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.languageschoolduration.title',
        'How many months will you be in language school?'
      )}
      subtitle={t(
        'page.question.languageschoolduration.subtitle',
        'This can be approximate time.'
      )}
    >
      <TextQuestion name="howLongInLanguageSchool" />
    </WithTitle>
  );
};

const CoverageStartDate = () => {
  const { t } = useTranslation();
  const currentYear = dayjs().year();
  return (
    <WithTitle
      title={t(
        'page.question.coveragestartdate.title',
        'When would you like your coverage to start?'
      )}
    >
      <DateQuestion
        name="coverageStartDate"
        yearBoundaries={{ min: currentYear - 2, max: currentYear + 1 }}
      />
    </WithTitle>
  );
};

const HasTaxNumber = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.taxnumber.title',
        'Do you have a German tax number?'
      )}
    >
      <BooleanQuestion name="hasTaxNumber" />
    </WithTitle>
  );
};

const MonthlyIncomeForNextYear = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.monthlyincomenextyear.title',
        'What is your monthly income for the next 12 months?'
      )}
      subtitle={t(
        'page.question.monthlyincomenextyear.subtitle',
        'This is gross income (i.e. before taxes)'
      )}
    >
      <TextQuestion name="monthlyIncomeForNextYear" type="number" />
    </WithTitle>
  );
};

const AgenturFurArbeitBenefits = () => {
  const { t } = useTranslation();
  const values: Array<{
    value: ArgenturFurArgeitBenefits;
    text: string;
  }> = [
    {
      value: 'GRUNDUNGSZUSCHUSS',
      text: t(
        'page.question.agenturfurarbeit.grundungzuschuss',
        'Gründungszuschuss'
      ),
    },
    {
      value: 'EINSTIEGSGELD',
      text: t('page.question.agenturfurarbeit.einstiegsgeld', 'Einstiegsgeld'),
    },
    {
      value: 'NONE',
      text: t('page.question.agenturfurarbeit.none', 'None of the above'),
    },
  ];
  return (
    <WithTitle
      title={t(
        'page.question.agenturfurarbeit.title',

        'Are you currently receiving any of these from the “Agentur für Arbeit”?'
      )}
    >
      <SelectorQuestion
        name="agenturFurArbeitBenefits"
        values={values}
        type="radio"
      />
    </WithTitle>
  );
};

const HasOtherIncome = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.hasotherincome.title',
        'Do you have any other form of income?'
      )}
      subtitle={t(
        'page.question.hasotherincome.subtitle',
        'e.g. Rental, pension, capital gains…'
      )}
    >
      <BooleanQuestion name="hasOtherIncome" />
    </WithTitle>
  );
};

const OtherIncome = () => {
  const { t } = useTranslation();
  const values: Array<{ value: OtherIncomeType; text: string }> = [
    {
      value: 'CAPITAL_GAIN',
      text: t('page.question.otherIncome.capitalgain', 'Capital gains'),
    },
    {
      value: 'RENTING',
      text: t('page.question.otherIncome.renting', 'Renting'),
    },
    {
      value: 'OTHER_EMPLOYMENT',
      text: t('page.question.otherIncome.otheremployment', 'Other Employment'),
    },
    {
      value: 'PENSION',
      text: t('page.question.otherIncome.pension', 'Pension'),
    },
    {
      value: 'OTHER',
      text: t('page.question.otherIncome.otherIncome', 'Other'),
    },
  ];
  return (
    <WithTitle
      title={t('page.question.otherincome.title', 'What form of income?')}
    >
      <SelectorQuestion values={values} name="otherIncome" type="checkbox" />
    </WithTitle>
  );
};

const OtherIncomeMonthlyRevenue = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.othermonthlyrevenue.title',
        'How much per month?'
      )}
    >
      <TextQuestion name="otherIncomeMonthlyRevenue" type="number" />
    </WithTitle>
  );
};

const HasPublicHealthInsurance = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.haspublichealthinsurance.title',
        'Do you currently have any public health insurance?'
      )}
      hint={{
        title: t(
          'page.question.haspublichealthinsurance.hint.title',
          'What is public insurance?'
        ),
        description: t(
          'page.question.haspublichealthinsurance.hint.description',
          'Public insurance refers to state insurance or universal health care.'
        ),
      }}
    >
      <BooleanQuestion name="hasPublicHealthInsurance" />
    </WithTitle>
  );
};

const HasGermanPublicHealthInsurance = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.hasgermanpublichealthinsurance.title',
        'Are you currently on public health insurance in Germany?'
      )}
    >
      <BooleanQuestion name="hasGermanPublicHealthInsurance" />
    </WithTitle>
  );
};

const InsuranceTwelveMonths = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.insurancetwelvemonths.title',
        'Have you had that insurance for the last 12 months?'
      )}
    >
      <BooleanQuestion name="insuranceTwelveMonths" />
    </WithTitle>
  );
};

const InsuranceTwentyFourMonths = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.insurancetwentyfourmonths.title',
        'Have you been publicly insured for at least 24 months in the last 5 years?'
      )}
    >
      <BooleanQuestion name="insuranceTwentyFourMonths" />
    </WithTitle>
  );
};

const PastFiveYearsInGermany = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.pastfiveyearsingermany.title',
        'Have you lived in Germany at any point in the last 5 years?'
      )}
    >
      <BooleanQuestion name="pastFiveYearsInGermany" />
    </WithTitle>
  );
};

const HadOneDayPublicHealthInsurance = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.hadonedaypublichealthinsurance.title',
        'Have you had public health insurance for at least 1 day in the last 5 years in EU, EEA, Switzerland or the UK?'
      )}
    >
      <BooleanQuestion name="hadOneDayPublicHealthInsurance" />
    </WithTitle>
  );
};

const ExpatOrPrivateHealthInsuranceIncoming = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.expatorprivatehealthinsuranceincoming.title',
        'Do you have an expat (incoming) or private health insurance in Germany?'
      )}
    >
      <BooleanQuestion name="expatOrPrivateHealthInsuranceIncoming" />
    </WithTitle>
  );
};

const EHICCard = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.ehiccard.title',
        'Do you want to use the insurance from your home country in Germany?'
      )}
      subtitle={t(
        'page.question.ehiccard.subtitle',
        'If your home state/public insurance provider agreed to cover your stay in Germany during studies, you can keep your home insurance, and have access to healthcare in Germany by presenting the relevant document, such as EHIC (European Health Insurance Card).'
      )}
      hint={{
        title: t(
          'page.question.ehiccard.hint.title',
          'Should I stay on my home public insurance?'
        ),
        description: t(
          'page.question.ehiccard.hint.description',
          'There are certain scenarios in which you most likely have to get a german insurance. For example, if you start working during your studies or move your permanent residence to Germany. [Help me choose]({{euStudentsToGermanyFaq}})',
          { euStudentsToGermanyFaq: faq.euStudentsComingToGermany }
        ),
      }}
    >
      <BooleanQuestion name="ehicCard" />
    </WithTitle>
  );
};

const CurrentlyOnPrivate = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.currentlyOnPrivate.title',
        'Are you currently on private health insurance in Germany?'
      )}
      subtitle={t(
        'page.question.currentlyOnPrivate.subtitle',
        'Select no if it’s an incoming/expat insurance.'
      )}
    >
      <BooleanQuestion name="currentlyOnPrivate" />
    </WithTitle>
  );
};

const LastInsuranceInGermany = () => {
  const { t } = useTranslation();
  const values: Array<{ value: LastInsuranceInGermanyType; text: string }> = [
    {
      value: 'PUBLIC',
      text: t(
        'page.question.lastInsuranceInGermany.public',
        'Public (statutory)'
      ),
    },
    {
      value: 'PRIVATE',
      text: t('page.question.lastInsuranceInGermany.private', 'Private'),
    },
  ];

  return (
    <WithTitle
      title={t(
        'page.question.lastInsuranceInGermany.title',
        'What was your last insurance in Germany?'
      )}
    >
      <SelectorQuestion
        name="lastInsuranceInGermany"
        values={values}
        type="radio"
      />
    </WithTitle>
  );
};

const EmploymentWithinTwoMonthsOfArrival = () => {
  const { t } = useTranslation();
  return (
    <WithTitle
      title={t(
        'page.question.employmentWithinTwoMonthsOfArrival.title',
        'Does your employment start within 2 months of your arrival in Germany?'
      )}
    >
      <BooleanQuestion name="employmentWithinTwoMonthsOfArrival" />
    </WithTitle>
  );
};

export { ConfirmNotSignedUpByEmployer } from './confirmSignedUpByEmployer';

export {
  Name,
  DateOfBirth,
  Gender,
  Address,
  Occupation,
  UniversityName,
  JobTitle,
  FirstTimeEmployedInGermany,
  PreviousInsurer,
  UnlistedPreviousInsurer,
  EmployerName,
  EmployerAddress,
  BeginningOfContract,
  AnnualIncome,
  PlaceAndCountryOfBirth,
  Nationality,
  ReceivesPension,
  DependentsToGetCovered,
  HasDependentChildren,
  GermanPensionNumber,
  LastNameAtBirth,
  PreviousCountryOfInsurance,
  HasPublicHealthInsurance,
  HasGermanPublicHealthInsurance,
  InsuranceTwelveMonths,
  InsuranceTwentyFourMonths,
  PastFiveYearsInGermany,
  HadOneDayPublicHealthInsurance,
  ExpatOrPrivateHealthInsuranceIncoming,
  EHICCard,
  HasAddressInGermany,
  OtherSituation,
  OtherSituationDescription,
  HowLongInLanguageSchool,
  CoverageStartDate,
  HasTaxNumber,
  MonthlyIncomeForNextYear,
  AgenturFurArbeitBenefits,
  HasOtherIncome,
  OtherIncome,
  OtherIncomeMonthlyRevenue,
  UniversityStartDate,
  WorksMoreThanTwentyHours,
  UniversityInGermany,
  CurrentlyOnPrivate,
  LastInsuranceInGermany,
  EmploymentWithinTwoMonthsOfArrival,
  LastProviderInGermany,
};
