export type Provider = 'aok' | 'tk' | 'dak' | 'barmer' | 'bkk';

export const provider = ((): Provider => {
  const toReturn = process.env.REACT_APP_PROVIDER;
  if (!toReturn) {
    throw new Error('[Configuration error]: REACT_APP_PROVIDER isn’t set');
  }

  return toReturn as Provider;
})();

export const providerNameMapping: { [k in Provider]: string } = {
  aok: 'AOK',
  barmer: 'Barmer',
  dak: 'DAK',
  tk: 'TK',
  bkk: 'BKK firmus',
};

export const providerDomainMapping: { [k in Provider]: string } = {
  aok: 'signupaok.de',
  barmer: 'signupbarmer.de',
  dak: 'signupdak.de',
  tk: 'signuptk.de',
  bkk: '',
};

export const isMicrofrontend = (): boolean => {
  const toReturn = process.env.REACT_APP_MICROFRONTEND;
  if (toReturn === 'true') {
    return true;
  } else if (toReturn === 'false') {
    return false;
  }

  throw new Error(
    '[Configuration error]:Can’t determine if app is running in microfontend or not, make sure REACT_APP_MICROFRONTEND is set to either "true" or "false"'
  );
};
